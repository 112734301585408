import { connect } from "react-redux"

import {
  getHasSliceAndDiceLicence,
  hasAnAwardsSurvey
} from "../../../../account/store/account.selectors"
import SurveySelectionDashboardComponent from "../../views/SurveySelectionDashboard"

const mapState = state => {
  return {
    hasSliceAndDiceLicence: getHasSliceAndDiceLicence(state),
    hasAnAwardSurvey: hasAnAwardsSurvey(state)
  }
}

const SurveySelectionDashboard = connect(
  mapState,
  null
)(SurveySelectionDashboardComponent)

export default SurveySelectionDashboard
