/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React, { useCallback, useEffect, useState } from "react"

import useBusinessAppQuery from "../../../../hooks/useBusinessAppQuery"
import addParams from "../../../../utils/addParams"
import SliceAndDiceUpsell from "../../../sliceAndDice/components/SliceAndDiceUpsell"
import ListSurveys from "../../../ui/components/ListSurveys"
import DashboardTopBanner from "../../components/DashboardTopBanner"
import CreditsDisplay from "../containers/components/CreditsDisplay"
import { LIST_ORGANISATION_SURVEYS_V2 } from "../services/surveys.queries"

const SurveySelectionDashboard = ({
  draft,
  hasSliceAndDiceLicence,
  hasAnAwardSurvey
}) => {
  const paths = usePaths()

  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(0)
  const [isThereMore, setIsThereMore] = useState(true)

  useEffect(() => {
    addParams(hasSliceAndDiceLicence, "slice-and-dice", "inactive")
  }, [])

  const { errors, isLoading, getData, data } = useBusinessAppQuery(
    LIST_ORGANISATION_SURVEYS_V2,
    { enabledOnMount: false }
  )

  const onLoad = useCallback(pageProps => {
    getData({
      limit: 10,
      skip: pageProps ? pageProps * 10 : 0,
      filterStatus: draft
        ? {
            exclude: true,
            value: ["completed", "scheduled", "live"]
          }
        : {
            exclude: true,
            value: ["draft", "completed"]
          }
    })
  }, [])

  const onLoadMore = useCallback(() => {
    const currentPage = page + 1
    setPage(currentPage)
    onLoad(currentPage)
  }, [page])

  useEffect(() => {
    onLoad(0)
  }, [])

  useEffect(() => {
    const listOrganisationSurveys = data?.listOrganisationSurveysV2 ?? []

    setDataList(
      page === 0
        ? listOrganisationSurveys
        : dataList
        ? [...dataList, ...listOrganisationSurveys]
        : listOrganisationSurveys
    )

    if (data?.listOrganisationSurveysV2?.length === 10) {
      setIsThereMore(true)
    } else {
      setIsThereMore(false)
    }
  }, [data])

  return (
    <Flex flexDirection="column" width="100%" maxWidth="100%">
      <DashboardTopBanner
        title={draft ? "Draft Surveys Dashboard" : "Live Surveys Dashboard"}
        subtitle={
          draft
            ? "View your draft surveys"
            : "View your live & scheduled surveys"
        }
        ExtraButton={CreditsDisplay}
        buttonPath={
          dataList.length !== 0 ? paths.surveys.surveyCreation.index : null
        }
        buttonText="Create Survey"
      />
      <ListSurveys
        page={page}
        isLoading={isLoading}
        surveysError={errors}
        surveysToShow={dataList}
        isThereMore={isThereMore}
        onLoad={onLoadMore}
      />
      {hasAnAwardSurvey &&
        !hasSliceAndDiceLicence &&
        dataList.length > 0 &&
        !draft && <SliceAndDiceUpsell chooseCompanySize />}
    </Flex>
  )
}

export default SurveySelectionDashboard
