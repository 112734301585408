/* eslint-disable import/prefer-default-export */
import { gql } from "../../../../lib/Apollo"

export const LIST_ORGANISATION_SURVEYS = gql`
  query listOrganisationSurveys {
    listOrganisationSurveys {
      id
      title
      type
      submissions
      sliceAndDice
      participationRate
      createdAt
      meta {
        startDate
        endDate
        ... on OrganisationMeta {
          creditsPaid
        }
        ... on AwardsMeta {
          creditsPaid
        }
        ... on EmployeeStarterMeta {
          frequencySettings {
            frequency
            endDate
          }
          resultsEmails
        }
        ... on EmployeeExitMeta {
          frequencySettings {
            frequency
            endDate
          }
          resultsEmails
        }
      }
      status
      responseRate
    }
  }
`

export const GET_SURVEY_INFO = gql`
  query ($surveyId: String!) {
    getOrgSurveyInfo(surveyId: $surveyId) {
      type
      responseRate
      submissions
      sliceAndDice
    }
  }
`

export const DELETE_ORGANISATION_SURVEY = gql`
  mutation ($surveyId: ID!) {
    deleteOrganisationSurvey(surveyId: $surveyId)
  }
`

export const LIST_ORGANISATION_SURVEYS_V2 = gql`
  query listOrganisationSurveysV2(
    $limit: Int
    $skip: Int
    $filterStatus: PaginatedFilterStatus
  ) {
    listOrganisationSurveysV2(
      limit: $limit
      skip: $skip
      filterStatus: $filterStatus
    ) {
      id
      title
      type
      submissions
      sliceAndDice
      participationRate
      createdAt
      meta {
        startDate
        endDate
        ... on OrganisationMeta {
          creditsPaid
        }
        ... on AwardsMeta {
          creditsPaid
        }
        ... on EmployeeStarterMeta {
          frequencySettings {
            frequency
            endDate
          }
          resultsEmails
        }
        ... on EmployeeExitMeta {
          frequencySettings {
            frequency
            endDate
          }
          resultsEmails
        }
      }
      status
      responseRate
    }
  }
`
